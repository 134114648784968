/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useAppDispatch } from 'store/hooks'
import { setCaseListPeriod } from 'store/slices/caseListFilterSlice'
import { useIsMobile } from 'helpers/hooks/useIsMedia'

/* Component imports -------------------------------------------------------- */
import {
  IconButton,
  MenuItem,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { Field } from 'formik'
import {
  Select,
  TextField,
} from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'

/* Type imports ------------------------------------------------------------- */
import type { CodeLabel } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface SelectFilterContainerProps {
  mobile: string;
}

const SelectFilterContainer = styled.div<SelectFilterContainerProps>`
  display: grid;
  grid-template-columns: ${(props) => props.mobile === 'true' ? 'repeat(4, calc(25% - 10px))' : 'repeat(1, calc(30% - 10px)) calc(70% - 10px)'};
  justify-content: space-between;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const PeriodContainer = styled.div`
  width: 100%;

  @media ${(props) => props.theme.media.tablet} {
    width: 25%;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    margin-top: -15px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface MyCasesFiltersProps {
  disasterNatureList: CodeLabel[];
  isFetchingDisasterNatureList: boolean;
  departmentList: CodeLabel[];
  isFetchingDepartmentList: boolean;
  periodList: {value: string | undefined; label: string}[];
}

const MyCasesFilters: React.FC<MyCasesFiltersProps> = ({
  disasterNatureList,
  isFetchingDisasterNatureList,
  departmentList,
  isFetchingDepartmentList,
  periodList,
}) => {
  const isMobile = useIsMobile()
  const dispatch = useAppDispatch()

  return (
    <>
      <SelectFilterContainer mobile={isMobile ? 'true' : 'false'}>
        {
          isMobile &&
            <div>
              <FormBoldTitle>
                Type de sinistre
              </FormBoldTitle>
              <AutocompleteField
                name="natureSinistre"
                options={disasterNatureList.map((value) => value.code || '')}
                getOptionLabel={(option) => disasterNatureList.find((value) => value.code === option)?.libelle || ''}
                disabled={isFetchingDisasterNatureList}
                size="small"
                multiple
              />
            </div>
        }
        <div>
          <FormBoldTitle>
            Département
          </FormBoldTitle>
          <AutocompleteField
            name="departement"
            options={departmentList.map((value) => value.code || '')}
            getOptionLabel={(option) => departmentList.find((value) => value.code === option)?.libelle || ''}
            disabled={isFetchingDepartmentList}
            size="small"
          />
        </div>
        <Field
          component={TextField}
          name="rechercheLibre"
          placeholder="Rechercher dans le tableau"
          size="small"
          InputProps={
            {
              endAdornment: (
                <IconButton type="submit">
                  <SearchIcon />
                </IconButton>
              ),
            }
          }
        />
      </SelectFilterContainer>
      <PeriodContainer>
        <FormBoldTitle>
          Période à afficher
        </FormBoldTitle>
        <Field
          component={Select}
          name="dateDebut"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => dispatch(setCaseListPeriod(e.target.value))}
          displayEmpty
          size="small"
        >
          {
            periodList.map((value, index) => (
              <MenuItem
                value={value.value}
                key={`${value.value}-${index}`}
              >
                {value.label}
              </MenuItem>
            ))
          }
        </Field>
      </PeriodContainer>
    </>
  )
}

export default MyCasesFilters

/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  usePostInterventionMutation,
  useGetInterventionOriginRequestDeferredQuery,
  useGetInterventionReasonListQuery,
  useGetInterventionResultListQuery,
} from 'store/api'
import {
  isApiError,
  type ApiResponse,
} from 'helpers/fetchHelpers'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import { useIsReadOnly } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import {
  Checkbox,
  CircularProgress,
  Dialog,
  MenuItem,
} from '@mui/material'
import { Field } from 'formik'
import {
  Select,
  TextField,
} from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CloseButton from 'components/CloseButton/CloseButton'
import LongButton from 'components/LongButton/LongButton'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type {
  InterventionDossierCreatePayload,
  OrigineDemandeInterventionDifferee,
} from 'API/__generated__/Api'
import { ResultatIntervention } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
type Intervention = InterventionDossierCreatePayload

const rdvSchema = Yup.object().shape<Shape<Intervention>>({
  dateDebut: Yup.string().nullable().required('La date est obligatoire'),
  dateFin: Yup.string().nullable().required('La date est obligatoire'),
  commentaire: Yup.string(),
  resultat: Yup.mixed<ResultatIntervention>().required(),
  motifNonReparation: Yup.number().when('resultat', {
    is: ResultatIntervention.NonRepare,
    then: (schema) => schema.required('Le motif est obligatoire en cas de non-réparation'),
  }),
  differee: Yup.boolean(),
  origineDemandeInterventionDifferee: Yup.mixed<OrigineDemandeInterventionDifferee>(),
}).required()

type RdvForm = FormikContextType<Intervention>

/* Styled components -------------------------------------------------------- */
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0px -10px -10px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowInterventionButtonProps {
  caseId: string;
}

const CaseWorkflowInterventionButton: React.FC<CaseWorkflowInterventionButtonProps> = ({ caseId }) => {
  const isReadOnly = useIsReadOnly()
  const [ open, setOpen ] = useState<boolean>(false)

  const {
    currentData: reasonList = [],
    isFetching: isFetchingReasonList,
  } = useGetInterventionReasonListQuery()
  const {
    currentData: resultList = [],
    isFetching: isFetchingResultList,
  } = useGetInterventionResultListQuery()
  const {
    currentData: originList = [],
    isFetching: isFetchingOriginList,
  } = useGetInterventionOriginRequestDeferredQuery()
  const [
    submitNewIntervention,
  ] = usePostInterventionMutation()

  const onSubmit = (values: Intervention, { setSubmitting, resetForm }: FormikHelpers<Intervention>) => {
    const onFinish = (response: ApiResponse<void>) => {
      if (!isApiError(response)) {
        setOpen(false)
        setSubmitting(false)
        resetForm()
      } else {
        setSubmitting(false)
      }
    }

    submitNewIntervention({ caseId, data: values }).then(onFinish).catch(console.error)
  }

  const formikForm: RdvForm = useForm<Intervention>(
    {
      initialValues: {
        dateDebut: new Date().toISOString(),
        dateFin: new Date().toISOString(),
        commentaire: '',
        resultat: ResultatIntervention.Repare,
        motifNonReparation: undefined,
      },
      onSubmit: onSubmit,
      validationSchema: rdvSchema,
    },
  )

  const onClick = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const interventionResultOptions: SegmentedButtonOption<string>[] = resultList.map((option) => ({ value: option.code, label: option.libelle }))

  return (
    <>
      <CaseWorkflowStyledComponents.Button
        variant="contained"
        onClick={onClick}
        disabled={isReadOnly}
      >
        Renseigner les informations
      </CaseWorkflowStyledComponents.Button>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xl"
      >
        <CaseWorkflowStyledComponents.DialogTitle>
          Infos intervention
          <CloseButton handleClose={onClose} />
        </CaseWorkflowStyledComponents.DialogTitle>
        <Form form={formikForm}>
          <CaseWorkflowStyledComponents.DialogContent>
            <CaseWorkflowStyledComponents.DuoButtonsContainer>
              <div>
                <FormBoldTitle required>
                  Date d'arrivée
                </FormBoldTitle>
                <FormikDatePicker name="dateDebut" />
              </div>
              <div>
                <FormBoldTitle required>
                  Heure d'arrivée
                </FormBoldTitle>
                <FormikDatePicker
                  name="dateDebut"
                  time
                />
              </div>
            </CaseWorkflowStyledComponents.DuoButtonsContainer>
            <CheckboxContainer>
              <Checkbox
                onChange={() => formikForm.setFieldValue('differee', !formikForm.values.differee)}
                checked={formikForm.values.differee}
              />
              Intervention différée
            </CheckboxContainer>
            {
              formikForm.values.differee &&
                <>
                  <FormBoldTitle required>
                    À la demande de
                  </FormBoldTitle>
                  <Field
                    component={Select}
                    name="origineDemandeInterventionDifferee"
                    displayEmpty
                    value={formikForm.values.origineDemandeInterventionDifferee || ''}
                    renderValue={verifySelectFieldValue(formikForm.values.origineDemandeInterventionDifferee?.toString())}
                    disabled={isFetchingOriginList}
                  >
                    {
                      originList.map((value, index) => (
                        <MenuItem
                          value={value.code}
                          key={`${value.code}-${index}`}
                        >
                          {value.libelle}
                        </MenuItem>
                      ))
                    }
                  </Field>
                </>
            }
            <CaseWorkflowStyledComponents.DuoButtonsContainer>
              <div>
                <FormBoldTitle required>
                  Date de départ
                </FormBoldTitle>
                <FormikDatePicker name="dateFin" />
              </div>
              <div>
                <FormBoldTitle required>
                  Heure de départ
                </FormBoldTitle>
                <FormikDatePicker
                  name="dateFin"
                  time
                />
              </div>
            </CaseWorkflowStyledComponents.DuoButtonsContainer>
            <FormBoldTitle required>
              Résultat de l'intervention
            </FormBoldTitle>
            {
              isFetchingResultList ?
                <CircularProgress /> :
                <SegmentedButtons
                  options={interventionResultOptions}
                  setSelectedOption={(value) => formikForm.setFieldValue('resultat', value)}
                  selectedOption={formikForm.values.resultat}
                />
            }
            {
              formikForm.values.resultat === ResultatIntervention.NonRepare &&
                <>
                  <FormBoldTitle required>
                    Raison de non réparation
                  </FormBoldTitle>
                  <Field
                    component={Select}
                    name="motifNonReparation"
                    displayEmpty
                    value={formikForm.values.motifNonReparation || ''}
                    renderValue={verifySelectFieldValue(formikForm.values.motifNonReparation?.toString())}
                    disabled={isFetchingReasonList}
                  >
                    {
                      reasonList.map((value, index) => (
                        <MenuItem
                          value={value.id}
                          key={`${value.id}-${index}`}
                        >
                          {value.libelle}
                        </MenuItem>
                      ))
                    }
                  </Field>
                </>
            }
            <FormBoldTitle>
              Détails de l'intervention
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Votre message"
              name="commentaire"
              multiline
              rows={3}
            />
          </CaseWorkflowStyledComponents.DialogContent>
          <CaseWorkflowStyledComponents.DialogAction>
            <LongButton
              variant="outlined"
              onClick={onClose}
            >
              Annuler
            </LongButton>
            <LongButton
              type="submit"
              variant="contained"
              disabled={formikForm.isSubmitting}
            >
              Valider
            </LongButton>
          </CaseWorkflowStyledComponents.DialogAction>
        </Form>
      </Dialog>
    </>
  )
}

export default CaseWorkflowInterventionButton

/* Type imports ------------------------------------------------------------- */
import type { ChipColor } from 'components/ColoredSquareChip/ColoredSquareChip'

/* getCaseStatusColor helper function --------------------------------------- */
export const getCaseStatusColor = (code: string): ChipColor => {
  switch (code) {
    case 'C':
      return 'blue'
    case 'RA':
      return 'red'
    case 'R':
      return 'purple'
    case 'D':
      return 'orange'
    case 'X':
      return 'salmon'
    case 'M':
      return 'green'
    case 'T':
      return 'grey'
    default:
      return 'yellow'
  }
}

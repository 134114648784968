/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'
import CaseWorkflowJalonText from '../CaseWorkflowJalonText'
import CaseWorkflowInterventionButton from '../CaseWorflowButtons/CaseWorkflowInterventionButton'

/* Type imports ------------------------------------------------------------- */
import type { JalonIntervention } from 'API/__generated__/Api'
import { WorkflowEtat } from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowInterventionJalonCardProps {
  jalon: JalonIntervention;
  caseId: string;
}

const CaseWorkflowInterventionJalonCard: React.FC<CaseWorkflowInterventionJalonCardProps> = ({
  jalon,
  caseId,
}) => {

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          Intervention
        </CaseWorkflowStyledComponents.Title>
        {
          jalon.etat === WorkflowEtat.EnAttente ?
            <CaseWorkflowInterventionButton caseId={caseId} /> :
            <div />
        }
        <CaseWorkflowJalonText
          jalon={jalon.type.code}
          date={jalon.intervention?.dateDebut || ''}
          interventionDetails={jalon.intervention || undefined}
          state={jalon.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowInterventionJalonCard

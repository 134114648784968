/* Type imports ------------------------------------------------------------- */
import type { FeatureCollection } from 'geojson'
import type { Adresse } from 'API/__generated__/Api'

/* Helpers declaration ------------------------------------------------------ */
export const getAddress = (address?: Adresse | null): string => {
  return [
    address?.adresse1,
    address?.adresse2,
    address?.adresse3,
    address?.codePostal,
    address?.ville,
  ].join(' ')
}

export const fetchAutocompleteAddress = async (query: string = '', postCodes: string[] = [], limit: number = 20) => {
  if (!query || query.trim().length < 3) return null

  const response = await fetch(`https://api-adresse.data.gouv.fr/search/?q=${query}&limit=${limit}`).catch(console.error)

  if (!response?.ok) {
    console.error(`Address API failed with status: ${response?.status}`)
    return null
  }

  const data = await response.json() as FeatureCollection

  return data.features.filter((feature) => !postCodes.length ? true : postCodes.some((allowedCode) => (feature.properties?.postcode as string)?.includes(allowedCode)))
}

/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import { DragDropContext } from 'react-beautiful-dnd'
import StrictModeDroppable from 'components/StrictModeDroppable/StrictModeDroppable'
import QuoteInvoiceLine from './QuoteInvoiceLine'

/* Type imports ------------------------------------------------------------- */
import type { DropResult } from 'react-beautiful-dnd'
import type {
  Bordereau,
  CodeLabel,
  LigneDevis,
  LigneFacture,
  TauxTVA,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 40px 120px 90px 2fr repeat(6, 1fr) 20px;
  gap: 5px;
  padding: 10px 10px 10px 0px;
  align-items: center;

  @media ${(props) => props.theme.media.mobile.main} {
    gap: 1px;
    padding: 5px 5px 5px 0px;
  }
`

const CardLineContainer = styled(Card)`
  margin-bottom: 5px;
  font-weight: bold;
  text-transform: uppercase;
`

/* Component declaration ---------------------------------------------------- */
interface QuoteInvoiceLinesProps {
  lines: LigneDevis[];
  setFieldValue: (field: string, value: string | LigneDevis | LigneDevis[]) => void;
  tvaRateList: TauxTVA[];
  lineTypeList: CodeLabel[];
  measureUnitList: CodeLabel[];
  updateCounter: () => void;
  articleBordereauList: Bordereau[];
  diverseBordereauList: Bordereau[];
}

const QuoteInvoiceLines: React.FC<QuoteInvoiceLinesProps> = ({
  lines,
  setFieldValue,
  tvaRateList,
  lineTypeList,
  measureUnitList,
  updateCounter,
  articleBordereauList,
  diverseBordereauList,
}) => {

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const reorder = (list: LigneFacture[], startIndex: number, endIndex: number) => {
      const result = [ ...list ]
      const [ removed ] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)

      return result
    }

    const items = reorder(
      lines || [],
      result.source.index,
      result.destination.index,
    )

    setFieldValue('lignes', items)
    updateCounter()
  }

  return (
    <div>
      <CardLineContainer>
        <GridContainer>
          <div />
          <div>
            Type
          </div>
          <div>
            Code
          </div>
          <div>
            Article
          </div>
          <div>
            Quantité
          </div>
          <div>
            Unité
          </div>
          <div>
            P.U
          </div>
          <div>
            Prix HT
          </div>
          <div>
            TVA
          </div>
          <div>
            Prix TTC
          </div>
        </GridContainer>
      </CardLineContainer>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="droppable">
          {
            (provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {
                  lines?.map((item, index) => (
                    <QuoteInvoiceLine
                      key={`field-${index}`}
                      index={index}
                      lines={lines}
                      setFieldValue={setFieldValue}
                      measureUnitList={measureUnitList}
                      lineTypeList={lineTypeList}
                      tvaRateList={tvaRateList}
                      updateCounter={updateCounter}
                      articleBordereauList={articleBordereauList}
                      diverseBordereauList={diverseBordereauList}
                    />
                  ))
                }
                {provided.placeholder}
              </div>
            )
          }
        </StrictModeDroppable>
      </DragDropContext>
    </div>
  )
}

export default QuoteInvoiceLines

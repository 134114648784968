/* Framework imports -------------------------------------------------------- */
import React, {
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'
import fuzzysort from 'fuzzysort'

/* Module imports ----------------------------------------------------------- */
import { handleNumberVerification } from 'helpers/numberUtils'
import calculMontants from 'helpers/calculs-montants-dommages-indemnisations/src/calculMontants'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  Menu,
  MenuItem,
} from '@mui/material'
import {
  DragIndicatorRounded,
  MoreVertRounded,
} from '@mui/icons-material'
import { Field } from 'formik'
import {
  Select,
  TextField,
} from 'formik-mui'
import { Draggable } from 'react-beautiful-dnd'
import NumberField from 'components/FieldWithInputAdornment/NumberField'
import PriceField from 'components/FieldWithInputAdornment/PriceField'

/* Type imports ------------------------------------------------------------- */
import type {
  Bordereau,
  CodeLabel,
  LigneDevis,
  TauxTVA,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface GridContainerProps {
  grid?: 'title';
}

const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-columns: 40px 120px 90px ${(props) => props.grid === 'title' ? '8.2fr' : '2fr repeat(6, 1fr)'} 20px;
  gap: 5px;
  padding: 10px 10px 10px 0px;
  align-items: center;

  @media ${(props) => props.theme.media.mobile.main} {
    gap: 1px;
    padding: 5px 5px 5px 0px;
  }
`

const CardLineContainer = styled(Card)`
  margin-bottom: 5px;
`

const IconButton = styled(Button)`
  align-self: center;
  min-width: auto;
  width: 20px !important;
`

const MenuOverflow = styled(Menu)`
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    overflow-x: auto;
    max-height: 30vh;
  }
`

const Table = styled.table`
  padding: 0px 10px;
  border-spacing: 0px;

  thead {
    td {
      font-weight: bold;
    }
  }

  td {
    border: 1px solid ${(props) => props.theme.colors.inputgrey};
    padding: 5px 10px;
  }
`

const TableRow = styled.tr`
  font-size: 14px;
  height: 30px;

  &:hover {
    background-color: ${(props) => props.theme.colors.inputgrey};
    cursor: pointer;
  }

  td:first-of-type {
    font-weight: bold;
    color: ${(props) => props.theme.palette.primary.main};
  }
`

/* Component declaration ---------------------------------------------------- */
interface QuoteInvoiceLineProps {
  lines: LigneDevis[];
  setFieldValue: (field: string, value: string | LigneDevis | LigneDevis[]) => void;
  tvaRateList: TauxTVA[];
  lineTypeList: CodeLabel[];
  measureUnitList: CodeLabel[];
  updateCounter: () => void;
  index: number;
  articleBordereauList: Bordereau[];
  diverseBordereauList: Bordereau[];
}

const QuoteInvoiceLine: React.FC<QuoteInvoiceLineProps> = ({
  lines,
  setFieldValue,
  tvaRateList,
  lineTypeList,
  measureUnitList,
  updateCounter,
  index,
  articleBordereauList,
  diverseBordereauList,
}) => {
  const [ anchorLineMenu, setAnchorLineMenu ] = useState<null | SVGElement>(null)
  const [ anchorBordereauMenu, setAnchorBordereauMenu ] = useState<null | HTMLElement>(null)
  const [ results, setResults ] = useState<Bordereau[]>([])

  const filterOptions = (options: Bordereau[], inputValue: string) => {
    const basicFilter = options.filter((op) => op.libelle.includes(inputValue.toLowerCase()))

    const opt = {
      limit: 100, // don't return more results than you need!
      threshold: -100, // don't return bad results
      all: true,
      keys: [ 'code', 'libelle', 'description' ],
    }

    const fuzzyResults = fuzzysort.go(inputValue, options, opt).map(({ obj }) => obj).filter((op) => !basicFilter.some((v) => v.code === op.code))
    setResults([ ...basicFilter, ...fuzzyResults ])
  }

  const searchBordereau = (value: string) => {
    setFieldValue(`lignes[${index}].codeArticle`, value)

    if (value && value.length > 2) {
      if (lines?.[index].type === 'A') {
        filterOptions(articleBordereauList, value)
      } else {
        filterOptions(diverseBordereauList, value)
      }
      const field = document.getElementById(`lignes[${index}].codeArticle`)
      setAnchorBordereauMenu(field)
    }
  }

  const handleBordereauMenuClose = () => {
    setAnchorBordereauMenu(null)
  }

  const handleLineMenuClick = (event: React.MouseEvent<SVGElement>): void => {
    event.stopPropagation()
    setAnchorLineMenu(event.currentTarget)
  }

  const handleLineMenuClose = (): void => {
    setAnchorLineMenu(null)
  }

  const modifyAmount = (quantite: number = 0, prixUnitaireHT: number = 0, tauxTVA: number = 0): void => {
    setFieldValue(`lignes[${index}].prixHT`, calculMontants.getMontantHT({ quantite, prixUnitaireHT }).toFixed(2))
    setFieldValue(`lignes[${index}].prixTTC`, calculMontants.getMontantTTC({ quantite, prixUnitaireHT, tauxTVA }).toFixed(2))
  }

  const handleLineValueChange = (type: 'quantite' | 'prixUnitaire' | 'tva', value: string): void => {
    if (!lines?.[index]) {
      return
    }

    const lastIsDot = value?.[value.length - 1] === '.'
    const lastIsDotZero = value?.[value.length - 1] === '0' && value?.[value.length - 2] === '.'
    const newValue = handleNumberVerification(value, 2)
    const lineValues = lines[index]

    if (type === 'tva') {
      const newRate = tvaRateList.find((rate) => rate.tva.code === value)
      if (newRate === undefined) {
        return
      }
      setFieldValue(`lignes[${index}].tva`, newRate.tva.code)
      modifyAmount(lineValues.quantite || 0, lineValues.prixUnitaire || 0, newRate.taux)
    }

    const rate = tvaRateList.find((rate) => rate.tva.code === lineValues.tva)?.taux || 0
    const formatValue = () => `${newValue}${lastIsDot ? '.' : lastIsDotZero ? '.0' : ''}`

    if (type === 'quantite') {
      setFieldValue(`lignes[${index}].quantite`, formatValue())
      modifyAmount(newValue, lineValues.prixUnitaire || 0, rate)
    }
    if (type === 'prixUnitaire') {
      setFieldValue(`lignes[${index}].prixUnitaire`, formatValue())
      modifyAmount(lineValues.quantite || 0, newValue, rate)
    }
    updateCounter()
  }

  const onSelectBordereauClick = (bordereau: Bordereau) => {
    const newLine: LigneDevis = {
      ...structuredClone(lines?.[index]),
      codeArticle: bordereau.code,
      libelle: lines?.[index].type === 'A' ? bordereau.description : bordereau.libelle,
      prixUnitaire: bordereau.prixUnitaire,
      type: lines?.[index].type || 'A',
      unite: bordereau.unite,
      quantite: 1,
    }

    const rate = tvaRateList.find((rate) => rate.tva.code === newLine.tva)?.taux || 0
    setFieldValue(`lignes[${index}]`, newLine)
    modifyAmount(1, bordereau.prixUnitaire || 0, rate)
    handleBordereauMenuClose()
    updateCounter()
  }

  const getArticleTitle = () => {
    if (lines?.[index].type === 'COM') return 'Commentaire'
    if (lines?.[index].type === 'TI') return 'Titre'
    if (lines?.[index].type === 'ST') return 'Sous-titre'
    return 'Article'
  }

  const onDuplicateClick = () => {
    if (!lines?.length) return
    const newArray = [ ...lines ]
    const duplicateItem = { ...lines[index] }

    newArray.splice(index + 1, 0, duplicateItem)
    setFieldValue('lignes', newArray)
    handleLineMenuClose()
  }

  const onDeleteClick = () => {
    if (!lines?.length) return
    const newArray = [ ...lines ]

    newArray.splice(index, 1)
    setFieldValue('lignes', newArray)
    handleLineMenuClose()
  }

  const isDisabled = useMemo(() => {
    return articleBordereauList.some((l) => l.code === lines?.[index]?.codeArticle) || diverseBordereauList.some((l) => l.code === lines?.[index]?.codeArticle)
  }, [ lines?.[index]?.codeArticle ])

  return (
    <Draggable
      key={`drag-${index}`}
      draggableId={`drag-${index}`}
      index={index}
    >
      {
        (provided) => (
          <CardLineContainer
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <GridContainer grid={
              lines?.[index].type === 'COM' ||
              lines?.[index].type === 'ST' ||
              lines?.[index].type === 'TI' ?
                'title' :
                undefined
            }
            >
              <DragIndicatorRounded
                fontSize="large"
                color="secondary"
              />
              <Field
                component={Select}
                name={`lignes[${index}].type`}
                displayEmpty
                onBlur={updateCounter}
                size="small"
              >
                {
                  lineTypeList.map((value, index) => (
                    <MenuItem
                      value={value.code}
                      key={`${value.code}-${index}`}
                    >
                      {value.libelle}
                    </MenuItem>
                  ))
                }
              </Field>
              {
                lines?.[index].type !== 'TST' && lines?.[index].type !== 'TTI' ?
                  <Field
                    component={TextField}
                    id={`lignes[${index}].codeArticle`}
                    name={`lignes[${index}].codeArticle`}
                    value={lines?.[index].codeArticle || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => searchBordereau(e.target.value)}
                    placeholder="Code"
                    size="small"
                  /> :
                  <div />
              }
              <Field
                component={TextField}
                name={`lignes[${index}].libelle`}
                placeholder={getArticleTitle()}
                size="small"
                onBlur={updateCounter}
                disabled={lines?.[index].type === 'TST' || lines?.[index].type === 'TTI' || isDisabled}
              />
              {
                lines?.[index].type === 'A' &&
                  <React.Fragment>
                    <NumberField
                      name={`lignes[${index}].quantite`}
                      onChange={(e) => handleLineValueChange('quantite', e.target.value)}
                      size="small"
                    />
                    <Field
                      component={Select}
                      name={`lignes[${index}].unite`}
                      displayEmpty
                      size="small"
                      disabled={isDisabled}
                    >
                      {
                        measureUnitList.map((value, index) => (
                          <MenuItem
                            value={value.code}
                            key={`${value.code}-${index}`}
                          >
                            {value.libelle}
                          </MenuItem>
                        ))
                      }
                    </Field>
                    <PriceField
                      name={`lignes[${index}].prixUnitaire`}
                      value={lines?.[index].prixUnitaire}
                      onChange={(e) => handleLineValueChange('prixUnitaire', e.target.value)}
                      disabled={isDisabled}
                      size="small"
                    />
                    <PriceField
                      name={`lignes[${index}].prixHT`}
                      size="small"
                      disabled
                    />
                    <Field
                      component={Select}
                      name={`lignes[${index}].tva`}
                      value={lines[index].tva || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleLineValueChange('tva', e.target.value)}
                      displayEmpty
                      size="small"
                    >
                      {
                        !lines[index].tva &&
                          <MenuItem value="">
                            Sélectionner
                          </MenuItem>
                      }
                      {
                        tvaRateList.map((value, index) => (
                          <MenuItem
                            value={value.tva.code}
                            key={`${value.tva.code}-${index}`}
                          >
                            {value.tva.libelle}
                          </MenuItem>
                        ))
                      }
                    </Field>
                    <PriceField
                      name={`lignes[${index}].prixTTC`}
                      size="small"
                      disabled
                    />
                  </React.Fragment>
              }
              {
                (lines?.[index].type === 'TST' || lines?.[index].type === 'TTI') &&
                  <React.Fragment>
                    <div />
                    <div />
                    <div />
                    <PriceField
                      name={`lignes[${index}].prixHT`}
                      size="small"
                      disabled
                    />
                    <div />
                    <PriceField
                      name={`lignes[${index}].prixTTC`}
                      disabled
                      size="small"
                    />
                  </React.Fragment>
              }
              <IconButton
                variant="text"
                size="small"
              >
                <MoreVertRounded
                  fontSize="large"
                  onClick={handleLineMenuClick}
                />
              </IconButton>
              <MenuOverflow
                anchorEl={anchorBordereauMenu}
                open={Boolean(anchorBordereauMenu)}
                onClose={handleBordereauMenuClose}
                disableAutoFocus
              >
                {
                  lines?.[index].type === 'A' ?
                    <Table>
                      <thead>
                        <tr>
                          <td>
                            Article
                          </td>
                          <td>
                            Prix Unitaire
                          </td>
                          <td>
                            Description
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          results.map((bordereau, index) => (
                            <TableRow
                              key={`${bordereau.code}-${index}`}
                              onClick={() => onSelectBordereauClick(bordereau)}
                            >
                              <td>
                                {bordereau.code}
                              </td>
                              <td>
                                {`${bordereau.prixUnitaire?.toFixed(2)}€`}
                              </td>
                              <td>
                                {bordereau.description}
                              </td>
                            </TableRow>
                          ))
                        }
                      </tbody>
                    </Table> :
                    <Table>
                      <thead>
                        <tr>
                          <td>
                            Code
                          </td>
                          <td>
                            Description
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          results.map((bordereau, index) => (
                            <TableRow
                              key={`${bordereau.code}-${index}`}
                              onClick={() => onSelectBordereauClick(bordereau)}
                            >
                              <td>
                                {bordereau.code}
                              </td>
                              <td>
                                {bordereau.libelle}
                              </td>
                            </TableRow>
                          ))
                        }
                      </tbody>
                    </Table>
                }
              </MenuOverflow>
              <Menu
                anchorEl={anchorLineMenu}
                open={Boolean(anchorLineMenu)}
                onClose={handleLineMenuClose}
                onClick={(e): void => e.stopPropagation()}
              >
                <MenuItem onClick={onDuplicateClick}>
                  Dupliquer
                </MenuItem>
                <MenuItem onClick={onDeleteClick}>
                  Supprimer
                </MenuItem>
              </Menu>
            </GridContainer>
          </CardLineContainer>
        )
      }
    </Draggable>
  )
}

export default QuoteInvoiceLine

/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useIsReadOnly } from 'store/hooks'
import { useDeleteLegalDocumentMutation } from 'store/api'
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import {
  Delete,
  Plagiarism,
} from '@mui/icons-material'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import LegalDocumentsButton from './LegalDocumentsButton'

/* Type imports ------------------------------------------------------------- */
import type { ChipColor } from 'components/ColoredSquareChip/ColoredSquareChip'
import type {
  DocumentJuridique,
  DocumentJuridiqueType,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const Chip = styled(ColoredSquareChip)`
  width: 100%;
  height: 100%;
  min-height: 38px;
  white-space: pre-line;
`

const CardContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 150px 42px 42px 250px;
  gap: 10px;
  padding: 10px;
  padding-right: 15px;
  min-height: 60px;
  align-items: center;

  @media ${(props) => props.theme.media.mobile.portrait} {
    display: flex;
    flex-direction: column;
  }
`

const DateLine = styled.div`
  display: flex;
  gap: 5px;
`

/* Component declaration ---------------------------------------------------- */
interface LegalDocumentsCardProps {
  legalDocument?: DocumentJuridique;
  legalType: DocumentJuridiqueType;
}

const LegalDocumentsCard: React.FC<LegalDocumentsCardProps> = ({ legalDocument, legalType }) => {
  const isReadOnly = useIsReadOnly()

  const [
    deleteDocument,
    { isLoading: isDeletingDocument },
  ] = useDeleteLegalDocumentMutation()

  const onClickDocument = () => {
    window.open(legalDocument?.url || '', '_blank', 'noreferrer')
  }

  const onDeleteDocument = async () => {
    if (legalDocument?.id) {
      await deleteDocument(legalDocument?.id)
    }
  }

  const isValid: boolean | 'warning' | 'error' | 'expired' = useMemo(() => {
    if (!legalDocument)
      return false
    if (!legalDocument.dateValiditeMax)
      return true

    const dateValidite = new Date(legalDocument.dateValiditeMax)
    const daysDiff = (dateValidite.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
    if (daysDiff <= 0) {
      return 'expired'
    }
    if (daysDiff <= 15) {
      return 'error'
    }
    if (daysDiff <= 30) {
      return 'warning'
    }

    return true
  }, [ legalDocument ])

  const getColor = (): ChipColor => {
    switch (legalDocument?.statutDocument?.id) {
      case 1:
        return 'darkorange'
      case 3:
        return 'red'
      default:
        break
    }

    switch (isValid) {
      case 'error':
      case 'expired':
        return 'red'
      case 'warning':
        return 'darkorange'
      case true:
        return 'green'
      case false:
      default:
        return 'grey'
    }
  }

  return (
    <Card>
      <CardContent>
        <DateLine>
          {`${legalType.libelle}${legalDocument ? `: ${legalDocument.nom}` : ''}`}
        </DateLine>
        {
          ((isValid || !legalDocument) && !isReadOnly) ?
            <LegalDocumentsButton
              legalDocument={legalDocument}
              legalType={legalType}
            /> :
            <div />
        }
        {
          (isValid && !isReadOnly) ?
            <>
              <CustomIconButton
                label="Voir le document"
                Icon={Plagiarism}
                onClick={onClickDocument}
                variant="outlined"
              />
              <CustomIconButton
                label="Supprimer le document"
                Icon={Delete}
                onClick={onDeleteDocument}
                variant="contained"
                color="error"
                disabled={isDeletingDocument}
              />
            </> :
            <>
              <div />
              <div />
            </>
        }
        <Chip color={getColor()}>
          {legalDocument ? `${legalDocument.statutDocument?.libelle || 'Validé'} le ${DateUtils.APIStrToLocalDateString(legalDocument.dateDepot)}` : 'En attente'}
          {
            isValid !== false && legalDocument?.dateValiditeMax && legalDocument?.statutDocument?.id === 2 &&
            `\n${isValid !== 'expired' ? "Valide jusqu'au " : 'Périmé depuis le '}${DateUtils.APIStrToLocalDateString(legalDocument.dateValiditeMax)}`
          }
        </Chip>
      </CardContent>
    </Card>
  )
}

export default LegalDocumentsCard

/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Type imports ------------------------------------------------------------- */
import type { Theme } from '@emotion/react'

/* Type declarations -------------------------------------------------------- */
export type ChipColor = 'green' |
                        'orange' |
                        'darkorange' |
                        'red' |
                        'pink' |
                        'salmon' |
                        'yellow' |
                        'purple' |
                        'lightblue' |
                        'blue' |
                        'grey' |
                        'lightgrey' |
                        'darkgrey' |
                        'warning' |
                        'primary' |
                        'secondary'

const isValidHexColor = (stringToVerify?: string | null): boolean => {
  const hexColorPattern = /^#([0-9A-Fa-f]{3}){1,2}$/

  if (!stringToVerify || stringToVerify?.length !== 7 || !hexColorPattern.test(stringToVerify)) {
    return false
  }
  return true
}

export const getChipColor = (color: ChipColor, customColor: string, theme: Theme): string => {

  const cleanColor = isValidHexColor(customColor) ? customColor : color

  switch (cleanColor) {
    case 'green':
      return '#DAF4D4'
    case 'orange':
      return '#FBE7D3'
    case 'darkorange':
      return '#ffd48f'
    case 'red':
      return '#e86666'
    case 'salmon':
      return '#FFC0BC'
    case 'pink':
      return '#FBD4F8'
    case 'yellow':
      return '#fff9a6'
    case 'purple':
      return '#D7CAFB'
    case 'lightblue':
      return '#D6ECEE'
    case 'blue':
      return '#D4E0FD'
    case 'grey':
      return theme.colors.grey
    case 'lightgrey':
      return theme.colors.lightgrey
    case 'darkgrey':
      return theme.colors.darkgrey
    case 'primary':
      return theme.palette.primary.main
    case 'secondary':
      return theme.palette.secondary.main
    case 'warning':
      return theme.palette.warning.main
    default:
      return cleanColor
  }
}
/* Component declaration ---------------------------------------------------- */
export interface ColoredSquareChipProps {
  variant?: 'outlined' | 'filled';
  color: ChipColor;
  customColor?: string | null;
  textColor?: string;
  bold?: boolean;
  smaller?: boolean;
}

const ColoredSquareChip = styled.div<ColoredSquareChipProps>`
  background: ${(props) => props.variant === 'outlined' ? props.theme.colors.main : getChipColor(props.color, props.customColor || '', props.theme)};
  color: ${(props) => props.variant === 'outlined' ? getChipColor(props.color, props.customColor || '', props.theme) : props.textColor || '#000000'};
  border: ${(props) => props.variant === 'outlined' ? `2px solid ${getChipColor(props.color, props.customColor || '', props.theme)}` : ''};
  font-weight: ${(props) => props.bold ? 'bold' : 'initial'};
  text-align: center;
  align-self: center;
  width: fit-content;
  min-width: 40px;
  height: auto;
  border-radius: ${(props) => props.smaller ? '3px' : '4px'};
  padding: ${(props) => props.smaller ? '3px' : '5px'} 7px;
  font-size: ${(props) => props.smaller ? '12px' : '14px'};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
`

export default ColoredSquareChip

/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import { DatePicker } from '@mui/x-date-pickers'

/* Styled components -------------------------------------------------------- */
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px 0px 10px;

  .table-filter {
    height: 30px;
  }
`

interface SizeableDatePickerProps {
  size?: string;
}

const SizeableDatePicker = styled(DatePicker)<SizeableDatePickerProps>`
  .MuiInputBase-root {
    height: ${(props) => props.size === 'small' ? '40px' : ''};
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseListDateFilterProps {
  value: string;
  onChange: (value: string) => void;
  size?: string;
}

const CaseListDateFilter: React.FC<CaseListDateFilterProps> = ({
  value,
  onChange,
  size = 'small',
}) => {

  const getIsValidDate = () => (value || '').toString() && DateUtils.isValidDate(new Date(value))

  return (
    <Container>
      <SizeableDatePicker
        value={getIsValidDate() ? new Date(value) : null}
        onChange={(value) => value && DateUtils.isValidDate(new Date(value)) && onChange(value.toISOString())}
        size={size}
      />
    </Container>
  )
}

export default CaseListDateFilter

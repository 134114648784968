/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'
import CaseWorkflowMeasureRdvButton from '../CaseWorflowButtons/CaseWorkflowMeasureRdvButton'
import CaseWorkflowJalonText from '../CaseWorkflowJalonText'

/* Type imports ------------------------------------------------------------- */
import type {
  JalonPostRdvMetre,
  JalonRendezVousMetre,
} from 'API/__generated__/Api'
import { WorkflowEtat } from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowMeasureRdvJalonCardProps {
  jalon: JalonRendezVousMetre;
  caseId: string;
  jalonMeasureDone?: JalonPostRdvMetre | null;
  isSinapps: boolean;
}

const CaseWorkflowMeasureRdvJalonCard: React.FC<CaseWorkflowMeasureRdvJalonCardProps> = ({
  jalon,
  caseId,
  jalonMeasureDone,
  isSinapps,
}) => {

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          RDV métré
        </CaseWorkflowStyledComponents.Title>
        {
          jalon.etat === WorkflowEtat.EnAttente ?
            <CaseWorkflowMeasureRdvButton
              caseId={caseId}
              initialRdv={jalon.rendezVousMetre}
              state={jalon.rendezVousMetre ? WorkflowEtat.Fait : WorkflowEtat.EnAttente}
              isSinapps={isSinapps}
            /> :
            jalonMeasureDone && jalonMeasureDone.etat !== WorkflowEtat.Fait && jalonMeasureDone.etat !== WorkflowEtat.NonFait ?
              <CaseWorkflowMeasureRdvButton
                caseId={caseId}
                state={WorkflowEtat.NonAboutie}
                isSinapps={isSinapps}
              /> :
              <div />
        }
        <CaseWorkflowJalonText
          jalon={jalon.type.code}
          date={jalon.dateRendezVous || ''}
          state={jalon.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowMeasureRdvJalonCard
